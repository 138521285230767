var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-space-between",attrs:{"id":"app"}},[(!_vm.is_mobile)?_c('div',{staticClass:"box feedback-box",class:{
      'feedback-form-active': _vm.show_feedback_modal && !_vm.minimize_feedback_modal,
      'feedback-form-hidden':
        !_vm.show_feedback_modal && !_vm.minimize_feedback_modal,
      'feedback-form-minimized': _vm.minimize_feedback_modal,
    }},[_c('FeedbackForm',{on:{"toggle_modal":function($event){_vm.minimize_feedback_modal = !_vm.minimize_feedback_modal},"close_modal":function($event){_vm.minimize_feedback_modal = false;
        _vm.show_feedback_modal = false;}}})],1):_vm._e(),_c('NavBar'),(_vm.show_header)?_c('header',[_c('Header',{attrs:{"page_slug":_vm.current_page_slug,"title":_vm.custom_title}})],1):_vm._e(),_c('router-view',{on:{"hide_header":function($event){_vm.show_header = false},"update_title":function($event){return _vm.set_new_title($event)}}}),_c('footer',[_c('Footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }